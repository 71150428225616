<template>
  <DefaultLayout>
    <template v-slot:header>
      <h1>{{ $t("logout.pageTitle") }}</h1>
    </template>
    <v-container fluid fill-height>
      <v-row justify="center" align="center" class="ml-1">
        <v-col cols="10" md="8" lg="6" xl="4">
          <Logout />
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
// @ is an alias to /src
import DefaultLayout from "@/layouts/default.vue";
import Logout from "@/components/_system/logout.vue";

export default {
  name: "home",
  components: {
    DefaultLayout,
    Logout,
  },
};
</script>
