<template>
  <v-card color="bg" elevation="0">
    <v-card-text> {{ $t("logout.body") }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" class="black--text" @click="$router.push('/')">{{ $t("logout.backToLogin") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { clearLogoutTimer } from "../../helpers/timeout.js";

export default {
  data() {
    return {};
  },
  mounted() {
    clearLogoutTimer();
    this.$store.dispatch("logout");
  },
  methods: {},
};
</script>
